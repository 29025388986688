import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState()],
  state: {
    showNavMenu: false,
    slang: 'zh',
    showLang: {
      titleh1: '分享玩赚的乐趣',
      titleh2: 'PLAY FOR FUN   PLAY TO EARN'
    }
  },
  getters: {
  },
  mutations: {
    changslang (state, str) {
      state.slang = str
    },
    changShowMenu (state, str) {
      state.showNavMenu = str
    },
    changeLang (state, str) {
      state.showLang = str
    }
  },
  actions: {
  },
  modules: {
  }
})
